/* eslint-disable react/no-danger */
import React, { FC, useEffect } from 'react';
import { ContentPageLayout } from '@components/Layout';
import { observer } from 'mobx-react';
import { useStores } from '@stores';
import { DocumentAccordion } from '@components/DocumentAccordion/DocumentAccordion';
import { TextSection } from '@components/TextSection';

interface IResidentActivityTenantDemocracyPage {
}


const ResidentActivityTenantDemocracyPage: FC<IResidentActivityTenantDemocracyPage> = observer(() => {
  const {
    agreementStore: {
      getTenantDemocracyDocuments, tenantsDemocracyDocuments: allDocuments, selectedAgreementId, documentState,
    }
  }: {
    agreementStore: IAgreementStore;
  } = useStores();

  useEffect(() => {
    if (documentState !== 'Loading' && documentState !== 'Error' && selectedAgreementId && !allDocuments.find((tenantDocObj) => tenantDocObj.agreement_id === selectedAgreementId)) {
      getTenantDemocracyDocuments(selectedAgreementId);
    }
  }, [documentState, selectedAgreementId, allDocuments, getTenantDemocracyDocuments]);

  const documents = allDocuments.find((docObj) => docObj.agreement_id === selectedAgreementId)?.documents;
  return (
    <ContentPageLayout>
      <TextSection slug="tenant-democracy" />
      <DocumentAccordion loading={documentState === 'Loading'} documentType="tenantdemocracy" items={documents as any} />
    </ContentPageLayout>
  );
});

export default ResidentActivityTenantDemocracyPage;
